'use client';

import Image from 'next/image';
import type { FC } from 'react';
import SignInContainer from '~containers/sign-in-container';
import { S3_BUCKET_IMAGES } from '~constants/etc';

const LoginClientPage: FC = () => {
  return (
    <div className="flex w-full max-w-md flex-col gap-6">
      <Image
        alt="logo"
        className="mx-auto"
        height={82}
        src={`${S3_BUCKET_IMAGES}/logo.webp`}
        width={144}
      />
      <SignInContainer />
    </div>
  );
};

export default LoginClientPage;
