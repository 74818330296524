import { joiResolver } from '@hookform/resolvers/joi';
import {
  EyeInvisibleOutlined,
  LockStarOutlined,
  UserOutlined,
} from '@packages/icons-react';
import Joi from 'joi';
import { type FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { signIn } from 'next-auth/react';
import { setCookie } from 'nookies';
import dayjs from 'dayjs';
import jwt from 'jsonwebtoken';
import Link from 'next/link';
import Image from 'next/image';
import { Input } from '~components/input';
import { axiosInstance } from '~libs/axios-instance';
import { encrypt } from '~libs/encrypt';
import HttpErrorHandler from '~components/http-error-handler';
import { Button } from '~components/button';
import { ButtonColors } from '~constants/components';
import Card from '~components/widgets/card';
import PageUrls from '~constants/page-urls';
import { S3_BUCKET_IMAGES } from '~constants/etc';

interface SignInFormProps {
  username: string;
  password: string;
}

const SignInContainer: FC = () => {
  const { t } = useTranslation(['forms', 'buttons', 'common']);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const schemaErrorsSignInForm = Joi.object({
    username: Joi.string()
      .required()
      .messages({
        'any.required': t('forms:error-username-empty'),
        'string.empty': t('forms:error-username-empty'),
      }),
    password: Joi.string().messages({
      'any.required': t('forms:error-password-empty'),
      'string.empty': t('forms:error-password-empty'),
    }),
  }).unknown(true);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignInFormProps>({
    resolver: joiResolver(schemaErrorsSignInForm),
  });

  const handleRequestLogin: SubmitHandler<SignInFormProps> = async (values) => {
    let response;
    const { username, password } = values;
    try {
      if (!executeRecaptcha) {
        return;
      }
      const token = await executeRecaptcha('login');
      const encryptPassword = encrypt(password);
      response = await axiosInstance.post('/authentication/login', {
        username: username.trim().toLowerCase(),
        password: encryptPassword,
        token,
      });
    } catch (error) {
      HttpErrorHandler(error);
    }
    if (response) {
      const jwtDecoded = jwt.decode(response.data.accessToken);
      setCookie(undefined, 'access_token', response.data.accessToken, {
        maxAge: dayjs(jwtDecoded.exp * 1000).diff(dayjs(), 's'),
        path: '/',
      });
      await signIn('credentials', {
        accessToken: response.data.accessToken,
      });
    }
  };

  return (
    <Card>
      <div className="animated-container">
        <Image
          alt="clubs"
          className="card-image card-clubs"
          height="322"
          src={`${S3_BUCKET_IMAGES}/cards-clubs.webp`}
          width="352"
        />
        <Image
          alt="hearts"
          className="card-image card-hearts"
          height="228"
          src={`${S3_BUCKET_IMAGES}/cards-hearts.webp`}
          width="208"
        />
        <Image
          alt="spades"
          className="card-image card-spades"
          height="322"
          src={`${S3_BUCKET_IMAGES}/cards-spades.webp`}
          width="352"
        />
        <Image
          alt="diamonds"
          className="card-image card-diamonds"
          height="228"
          src={`${S3_BUCKET_IMAGES}/cards-diamonds.webp`}
          width="208"
        />
      </div>
      <div className="mb-4 flex flex-col items-center gap-2">
        <p className="text-sm">{t('common:welcome')}</p>
        <p className="text-primary text-2xl font-bold">{t('common:login')}</p>
      </div>
      <form
        className="flex w-full flex-col gap-4"
        onSubmit={handleSubmit(handleRequestLogin)}
      >
        <Input
          {...register('username')}
          errors={
            errors.username?.message ? [errors.username.message] : undefined
          }
          placeholder={t('forms:place-holder-username')}
          prefix={<UserOutlined />}
        />
        <Input
          className="pr-12"
          suffix={<EyeInvisibleOutlined className="text-gray/30 text-base" />}
          {...register('password')}
          errors={
            errors.password?.message ? [errors.password.message] : undefined
          }
          placeholder={t('forms:place-holder-password')}
          prefix={<LockStarOutlined />}
          type="password"
        />

        <div className="text-gray/60 my-2.5 flex items-center justify-between px-2.5 text-sm">
          <div className="flex select-none items-center gap-1">
            <input
              className="accent-primary cursor-pointer"
              id="rememberMe"
              type="checkbox"
            />
            <label className=" cursor-pointer" htmlFor="rememberMe">
              {t('common:remember-me')}
            </label>
          </div>
          <Link href={PageUrls.ForgotPassword}>
            <div className="flex items-center gap-1">
              <LockStarOutlined />
              <p>{t('common:forgot-password')}?</p>
            </div>
          </Link>
        </div>

        <Button loading={isSubmitting} type="submit">
          {t('buttons:btn-login')}
        </Button>
        <Button color={ButtonColors.Default} href={PageUrls.Register}>
          {t('buttons:btn-create-account')}
        </Button>
        <Button color={ButtonColors.Transparent} href={PageUrls.Contact}>
          {t('buttons:btn-contact-service')}
        </Button>
      </form>
    </Card>
  );
};

export default SignInContainer;
