import { cn } from 'class-merge';

const Card: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div
      className={cn(
        'from-vanilla to-yellow relative w-full max-w-md rounded-2xl bg-gradient-to-b p-0.5',
        className,
      )}
    >
      <div className="from-vanilla via-orange to-yellow absolute inset-0 -z-10 rounded-2xl bg-gradient-to-b blur-xl" />
      <div className="bg-layout w-full rounded-2xl p-8">{children}</div>
    </div>
  );
};

export default Card;
